<template>
  <div id="setup" class="d-flex align-center justify-center">

    <v-card class=" col-xs-10 col-sm-8 col-md-8 col-lg-8 pa-0 mx-2" :loading=" isLoading ? 'white' : null">

      <div class="d-flex flex-row youtube-input align-stretch align-items-stretch fill-height" :class="{'flex-row desktop' : $vuetify.breakpoint.mdAndUp, 'flex-column' : $vuetify.breakpoint.smAndDown}">

        <div class="col-xs-12 col-sm-12 col-md-5 primary white--text d-flex flex-column align-center justify-center text-xs-center text-sm-center">
          <img src="@/assets/Youtorial-white.png" alt="">
          <div class="headline font-weight-bold my-3 white--text">Anyone can create a Youtorial!</div>
          <p class="">You can take ANY video, even if you don't own it, and make a Youtorial.</p>
          <p class="">If you are a content creator, you can share this link in your comments or video and let your viewers have a cool new experience.</p>

        </div>

        <div class="col-xs-12 col-sm-12 col-md-7 align-center d-flex flex-column justify-center white">
          <v-card-text class="pt-2">
            <v-text-field
            v-model="urlRaw"
            :rules="urlRules"
            color="secondary darken-2"
            label="Enter Youtube URL or Video ID"
            required
            ></v-text-field>
            <!-- <p>https://www.youtube.com/watch?v=XaNELscDtms&t=6s</p> -->
          </v-card-text>
          <v-card-actions class="justify-space-between" style="width:100%">
            <template v-if="!isSuccessful">
              <!-- <v-btn>Help</v-btn> -->
              <v-spacer></v-spacer>
              <v-btn class=" px-4" color="grey darken-4" dark @click="getId" :class="{'gradientBG':isLoading}">
                <template v-if="isLoading">
                  <v-progress-circular
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                  Finding...
                </template>
                <template v-else>Find Video</template>
              </v-btn>
            </template>
            <template v-else>
              <v-alert dense text type="success" style="width:100%; position:relative">
                Video found, setting it up now...
                <v-progress-circular
                style=" position:absolute; right:1%; top:5px;"

                indeterminate
                color="green"
                ></v-progress-circular>
              </v-alert>
            </template>
          </v-card-actions>
        </div>

      </div>
    </v-card>

  </div>
</template>

<script>

import {cloud} from '@/firebase'

export default {
  name: "setup",
  props: ['user'],
  components:{},
  data(){
    return{
      urlRaw: null,
      urlId: null,
      isLoading: false,
      isSuccessful:false,
      urlRules: [
        v => !!v || 'We need a url ;)',
      ]
    }
  },
  methods: {
    getId(){
      this.isLoading = true;
      //  https://www.youtube.com/watch?v=ODuEl4oNae0
      let data = this.$getVideoId(this.urlRaw);
      process.env.NODE_ENV === "development" ? console.log( 'data from url', data ) : null;
      this.urlId = data.id;
      this.addNewVideo();
    },
    addNewVideo(){
      let vThis = this;
      let setVideoDetails = cloud.httpsCallable('setVideoDetails');
      let user = {}
      user['displayName'] = this.user.displayName;
      if(Object.hasOwnProperty.call(this.user, 'avatar') && this.user.showAvatar){
        user['avatar'] = this.user.avatar;
        user['bg'] = this.user.bg;
      }
      else{
        user['avatar'] = this.user.photoURL;
      }
      setVideoDetails({video: this.urlId, user: user}).then(function(result) {
        console.log('results from getVideoDetails', result);
        process.env.NODE_ENV === "development" ? console.log( 'results from setVideoDetails', result ) : null;
        process.env.NODE_ENV === "development" ? console.log( result.data.code, result.data.code === "success") : null;
        if(result.data.code === "success"){
          vThis.isLoading = false;
          vThis.isSuccessful = true;
          // GA: New Video
          vThis.$logEvent('Created','New', `${vThis.urlId} - ${result.data.details}`);

          setTimeout(function(){
            vThis.$router.push({ name: 'create', params: {videoId:result.data.details}})
          }, 3000);
        }
        else{
          alert(result.data.message);
        }
        // // action, bus_obj, icon, color, details, user, lockId
        // vThis.$logEvent('added', `team member`, 'person_add', 'brand-dark-blue', `${vThis.teamForm.fName} ${vThis.teamForm.lName}`, vThis.user).then(()=>{
        //
        //   // this.$ga.event('category', 'action', 'label', 123)
        //   vThis.$ga.event('Team', 'create')
        //   // stop loading
        //   vThis.addingMember = false;
        //   //close dialog
        //   vThis.closeDialog();
        // });
      }).catch(function(error) {

      });
    }
  }

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#setup{
  .v-card{
    // background: rgba(18, 91, 152, 0.05)
    .desktop{
      height: 60vh;
    }
  }
  .youtube-input{

  }

}

</style>
